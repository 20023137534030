.collapse-button {
  cursor: pointer;
  padding: 0.5rem;
  width: 80%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  max-width: 1342px;
  border-left: 2px solid;
  margin-bottom: 15px !important;
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%) !important;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
}

.border-operational {
  border-color: rgb(82, 205, 155);
}

.border-operational:hover {
  border-left: 3px solid;
  border-color: rgb(82, 205, 155);
}

.border-failure {
  border-color: rgb(226, 172, 55);
}

.border-failure:hover {
  border-left: 3px solid;
  border-color: rgb(226, 172, 55);
}

.button-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-label {
  padding-left: 15px;
  word-break: break-all;
  color: black !important;
}

.no-services-output {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-services-output p {
  margin: 10px 0 15px 0;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 600;
  width:80%;
  text-align: center;
}

.no-services-engines {
  width: 45%;
}

.status-label {
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  padding-right: 10px;
  width: 15px !important;
  height: 15px !important;
}

.operational {
  color: rgb(82, 205, 155);
}

.incident {
  color: rgb(226, 172, 55);
}

.content {
  width: 100%;
  display: none;
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.collapsed {
  display: none;
}

.expanded {
  display: block;
}

.cards-wrapper {
  width: 100%;
  max-width: 1342px;
  margin-bottom: 15px !important;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 1px 0 0 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.status-cards {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .collapse-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 1rem;
  }

  .collapse-button p {
    margin: 5px;
  }

  .product-label {
    padding-left: 10px;
  }

  .status-label {
    padding-left: 10px;
  }

  .status-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
