.incident-time-details {
  margin: 8px;
}

.data-block {
  margin: 15px 0 10px 0;
}

.incident-details-paragraph {
  margin: 0;
  padding: 0;
}

.details-block {
  display: flex;
  margin: 20px 0 20px 8px;
}

.private {
  color: #ffd700!important;
}

.incident-update-time {
  margin-right: .5%;
}

.icon-delete-detail {
  cursor: pointer;
}