.incidents-container {
  width: 80%;
  margin: 0 auto;
  min-height: 50vh;
  padding: 2rem;
}

.incidents-table {
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%) !important;
  background: white;
  padding: 20px;
  margin: 20px 0 20px 0;
  width: 100%;
  border-collapse: collapse;
}

.incidents-table tr td {
  border-top: none;
  border-bottom: 1px solid #eaeaea;
  padding: 1rem;
}

.zero-incidents {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 600;
}

.table-card {
  display: flex;
  justify-content: space-between;
}

.table-card p {
  margin: 0 0 0 5px;
}

.incident-date {
  font-weight: 600;
}

.incidents-table a {
  display: inline;
  color: #212121;
  text-decoration: none;
}

.incidents-container hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #eef1f6;
}

.incidents-back-link {
  background-color: transparent;
  color: #f82e52;
  text-decoration: none;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 600;
}

.incidents-anchor-icon {
  width: 10px !important;
  height: 15px !important;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .table-card {
    min-height: 50px;
  }
}
