#bc {
    display: flex;
}

#bc span {
    margin-right: 3px;
}

#bc, #bc a {
    color: #f82e52;
    font-size: 16px;
    margin-top: -19px!important;
}

#bc a:hover {
    text-decoration: underline;
}
