.principal {
    height: 50px !important;
    top: 0 !important;
    width: 100% !important;
    margin-left: 0px !important;
    background-color: #262b32 !important;
    position: fixed;
    z-index: 1;
}

.nav-links {
    padding-top: 15px !important;
}

#nav-li {
    display: inline;
    border-right: 1px solid #bbb;
}

#nav-li:last-child {
    border-right: none;
}

#nav-item {
    color: white;
    padding-left: 8px;
    padding-right: 8px;
}

#nav-item:hover {
    background-color: #1D7391;
}

.perfilbtn {
    position: fixed;
    z-index: 2;
    cursor: pointer;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    padding: 11px;
    margin-right: 10px;
}

.perfilbtn svg{
    font-size: 25px;
    padding-right: 5px;
}

.submenu-items {
    padding: 5px 5px 10px 10px;
    width: auto;
    flex-direction: column;
    background: #262b32;
    display: flex;
    border-radius: 0px 0px 5px 5px;
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 1;
    margin-right: 10px;
}

.submenu-items span {
    color: white;
    line-height: 2.5;
    height: 35px;
    cursor: pointer;
    width: 130px;
}

.submenu-items span:hover {
    background-color: #262b32 !important;
    border-radius: 5px;
    text-decoration: underline;
}