.icons-subtitle-content {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.icons-subtitles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle-icon {
  padding-right: 10px;
  padding-left: 10px;
  width: 15px !important;
  height: 15px !important;
}

.operational {
  color: rgb(40, 167, 69);
}

.failure {
  color: rgb(205, 37, 83);
}

.alert {
  color: rgb(63, 70, 173);
}

.maintenance {
  color: rgb(18, 100, 163);
}

.incident {
  color: rgb(255, 204, 51);
}
