.grid-header {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 1342px;
    left: 0;
    right: 0;
    width: 80%;
}

.grid-header-content {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

.grid-header-content p {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

.grid-header-subtitle p {
    font-weight: 500;
    font-size: 0.75rem;
}

@media screen and (max-width: 768px) {
    .grid-header-content p {
        font-size: 1rem !important;
    }

    .grid-header-subtitle {
        display: none;
    }
  }