.sidenav---sidenav---_2tBP {
    background-color: #262b32 !important;
}

.sidenav---sidenav-subnav---1EN61 {
    background-color: #262b32 !important;
}

.sidenav---sidenav-subnavitem---1cD47 {
    color: white !important;
}

.sidenav---navitem---9uL5T {
    cursor: pointer !important;
    color: white !important;
}

.sidenav---navitem---9uL5T:hover {
    background-color: #262b32 !important;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 220px !important;
}

.sidenav---sidenav---_2tBP {
    position: fixed;
}

.iconeMenu {
    font-size: 1.75em !important;
    color: #bbd5de !important;
}