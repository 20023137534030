.service-card {
  box-sizing: border-box;
  border-style: solid;
  border-color: #E8E8E8;
  border-width: 0 1px 1px 0;
  background-color: #fff;
  width: 100%;
  float: left;
}

.card {
  padding: 20px;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.card-top-label {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.service-name {
  font-weight: 600;
  overflow-wrap: break-word;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.service-subtitle {
  display: flex; 
  align-items: center;
  justify-content: flex-start;
}

.icon {
  width: 20px !important;
  height: 20px !important;
}

.operational {
  color: rgb(40, 167, 69);
}

.failure {
  color: rgb(205, 37, 83);
}

.alert {
  color: rgb(63, 70, 173);
}

.maintenance {
  color: rgb(18, 100, 163);
}

.incident {
  color: rgb(255, 204, 51);
}

.service-status-msg {
  color: #6a737d;
  font-size: 14px;
  padding-right: 1rem;
}

.card-top-label p {
  margin: 0;
  color: #6a737d;
  font-size: 14px;
}

.has-occurrence {
  text-decoration: none;
  color: #000000;
}