.detailed-incident-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.detailed-content {
  width: 80%;
}

.detailed-content p {
  margin: 0;
  padding: 0;
}

.bold-paragraph {
  font-weight: 700;
}

.incident-information {
  width: 950px;
  margin-top: 25px;
  margin-bottom: 32px;
}

.incident-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detailed-incident-icon {
  transform: scale(0.8);
  margin-right: 3px;
}

.incident-status p {
  font-weight: 700;
  line-height: 1.5;
  color: #414141;
}

.incident-title {
  font-weight: 700;
  margin-top: 0;
}

.incident-details {
  display: flex;
  flex-direction: row;
  text-align: justify;
}

.incident-details-left {
  padding-right: 72px;
  min-width: 60%;
}

.zero-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zero-details img {
  width: 50%;
}

.zero-details p {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  color: #A1A5AB;
}

.incident-details-right {
  width: 100%;
}

.incident-details-right p {
  line-height: 1.5;
  color: #414141;
  text-align: left;
}

.note {
  padding-bottom: 32px;
  padding-left: 28px;
  margin-left: 12px;
  border-left: 2px solid #e8e8e8;
  position: relative;
}

.note p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: #414141;
}

.note:last-child {
  padding-bottom: 0;
}

.incident-last-update {
  position: absolute;
  left: -9px;
  top: -8px;
  z-index: 1;
}

.last-update-icon {
  width: 1rem !important;
  height: 2rem !important;
  color: #e8e8e8;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e8e8e8;
  position: absolute;
  left: -5px;
  top: 9px;
  z-index: 5;
}

.incident-update-time {
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
  color: #6a737d;
}

.incident-affected-products {
  margin-bottom: 1rem;
}

.incident-affected-services {
  margin-bottom: 1rem;
}

.incident-details-status {
  margin-bottom: 1rem;
}

.detailed-content hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #eef1f6;
}

.back-link {
  background-color: transparent;
  color: #f82e52;
  text-decoration: none;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 600;
}

.anchor-icon {
  width: 10px !important;
  height: 15px !important;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .incident-information {
    width: 100%;
  }

  .incident-details {
    flex-direction: column;
    text-align: left;
  }

  .incident-details-left {
    padding: 0;
  }

  .incident-details-right {
    margin: 32px 0 0 0;
  }
}

@media screen and (max-width: 1050px) {
  .incident-information {
    width: 100%;
  }
}
