.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-boundary p {
  margin: 15px 0 0 0;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 600;
}