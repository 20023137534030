.login-container {
  background-color: #262b32;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.login {
  display: flex;
  justify-content: center;
  padding: 3%;
}

.login-card {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
}

.logo-img {
  width: 70%;
}

.Login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Login-field {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  margin-top: 10px;
}

.Login-field input {
  float: left;
  border-color: transparent;
  margin-right: 10px;
  width: 100%;
}

.Login-field input:focus {
  width: 100%;
  outline: none;
}

.Login-icon {
  display: flex;
  align-items: center;
  float: left;
  padding: 10px;
}

.Login-icon-left {
  display: flex;
  align-items: center;
  float: right;
  padding: 10px;
  padding-left: 0px;
}

.Login-form button {
  background-color: #f82e52;
  border: 0px;
  border-radius: 5px;
  color: white;
  height: 40px;
  width: 130px;
  transition: background-color 0.2s;
}

.Login-form button:hover {
  background-color: #F2143B;
}
