.main-principal {
  background-color: #f6f8fa;
}

.custom-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #2f343b;
  height: 4rem;
}

.navfiller {
  padding-left: 56px;
  padding-right: 56px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 1342px;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 72px;
}

.main-content {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards-wrapper {
  width: 80%;
  max-width: 1342px;
  margin-bottom: 56px;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 1px 0 0 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.status-cards {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}

.main-content hr {
  margin-bottom: 15px !important;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: rgb(236, 239, 241);
  max-width: 1342px;
  width: 80%;
}

.dashboard-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  font-family: "Red Hat Display", sans-serif;
}

.dashboard-footer p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}

.dashboard-footer a {
  text-decoration: none;
  color: #f82e52;
  font-weight: 600;
}

.anchor-icon {
  width: 10px !important;
  height: 15px !important;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .status-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .dashboard-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-footer a {
    margin-bottom: 10px;
  }

  .dashboard-footer p {
    font-size: 0.75rem;
  }
}
