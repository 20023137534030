.general-scope {
  border-bottom: 1px solid #e8e8e8;
  background-color: white;
}

.sticky-header {
  z-index: 1000;
  top: 0;
  position: fixed;
  width: 100%;
}

.section {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  padding-right: 56px;
  left: 0;
  right: 0;
  width: 100%;
}

.navbar {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 72px;
}

.logo {
  border-right: 1px solid #E8E8E8;
  padding-right: 10px;
}

.menu-left span {
  padding-right: 10px;
}

.header-span {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  padding-left: 1rem;
}
