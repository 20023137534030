.current-status {
  max-width: 65%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.alert-status-icon {
  width: 150px;
}

.current-status-text p,
h1 {
  text-align: center;
}

.current-status-text p {
  font-weight: bold;
  font-size: 18px;
}

.redirect-incidents {
  color: black;
}

.movidesk-label {
  cursor: pointer;
  color: #f82e52;
}

@media screen and (max-width: 768px) {
  .current-status-text h1 {
    font-size: 1.5rem;
  }

  .current-status-text p {
    font-size: 1rem;
  }
}
