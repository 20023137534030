.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 2rem;
}

.wrapper a {
  text-decoration: none;
  background-color: transparent;
}

.w2 {
  background-color: #262b32;
}

.w3 {
  background-color: #21252c;
}

.footer {
  padding: 2rem 4rem;
  color: #c7c9cc;
  width: 100%;
}

.footer-logo {
  width: 102px;
  height: 30px;
  margin-bottom: 2rem;
  overflow: overlay !important;
}

.footer-content {
  display: grid;
  grid-gap: 40px;
  grid-template: "group1 group1 group2 group3" / 1fr;
}

.footer-content img {
  vertical-align: middle;
  border-style: none;
}

.footer-title {
  color: #6a6e76;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-bottom: 1px solid rgba(106, 110, 118, 0.3);
}

.group1 {
  grid-area: "group1";
}
.group2 {
  grid-area: "group2";
}
.group3 {
  grid-area: "group3";
}

.group-links {
  display: flex;
  flex-wrap: wrap;
}

.links {
  padding-right: 4rem;
  min-width: 231px;
}

.links a {
  font-weight: 500;
  line-height: 2rem;
  display: block;
  color: #fbfcff;
}

.endeavor-img {
  padding-top: 2rem;
}

@media (max-width: 1304px) {
  .footer-content {
    display: block;
    grid-template: "group1" "group2 group3" / 50% 50%;
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 2rem;
  }

  svg {
    max-width: 100%;
  }

  .footer-content {
    grid-template:
      "group1"
      "group2"
      "group3" / 100%;
  }

  .group-links {
    margin-bottom: 1 rem;
    display: flex;
    flex-direction: column;
  }

  .links {
    padding-right: 0;
  }
}
