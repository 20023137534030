body {
  margin: 0;
  background-color: #f6f8fa;
  font-family: "Lato", sans-serif;
}

.navfiller {
  padding-left: 56px;
  padding-right: 56px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 72px;
}